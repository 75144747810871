import { prepareFilterParam, prepareSelectParam } from '../lib/queryParams'
import { API_URL_NEW } from '../lib/endpoints'
import axios from 'axios';
import { ALLOWED_QUERY_OPERATOR, FUNDING_STAGES, FUNDING_STATUS } from '../lib/constants';

const exitApi = {

    getExitData: (selectableFields = [], filters, limit, page, sort) => {

        const fields = prepareSelectParam([
            'id', 'investedin_data',
            ...selectableFields
        ]);

        // get only acquisition stage funding rounds
        const arrFilters = [{ 
            key: 'funding_stage_filter', 
            value: [FUNDING_STAGES.acquisition], 
            op: ALLOWED_QUERY_OPERATOR.equal 
        }];

        if (filters.indexOf('verification_status') === -1) {
            arrFilters.push({ key: 'verification_status', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }) // exclude draft funding rounds
        }

        const permanentFilters = prepareFilterParam(arrFilters);
        
        const url = `${API_URL_NEW}/funding-breakdown?fields=${fields}&filters=${filters}^,${permanentFilters}&limit=${limit}&page=${page}&sort=${sort}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        exits: response.data.data,
                        totalRecords: response.data.navigation.total
                    });
                } else {
                    resolve({
                        exits: [],
                        totalRecords: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getExitDataCount: (filters) => {
        const arrFilters = [
            { key: 'funding_stage_id', value: [FUNDING_STAGES.acquisition], op: ALLOWED_QUERY_OPERATOR.equal }, // get only acquisition stage funding rounds
        ];

        if (filters.indexOf('verification_status') === -1) {
            arrFilters.push({ key: 'verification_status', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }) // exclude draft funding rounds
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/funding/setting/count?filters=${filters}^,${permanentFilters}`;
        
        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        totalRecords: response.data.data,
                    });
                } else {
                    resolve([]);
                }
            }).catch((err) => reject(err));
        });
    },

    // get total exits
    getExitsCount: () => {
        const permanentFilters = prepareFilterParam([
            { key: 'funding_stage_id', value: [FUNDING_STAGES.acquisition], op: ALLOWED_QUERY_OPERATOR.equal }, // get only acquisition stage funding rounds
            { key: 'verification_status', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq } // exclude draft funding rounds
        ]);

        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/funding/setting/count?filters=${permanentFilters}`;
            axios.get(url).then(async (response) => {  
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "77"
                }
                console.log("ERROR: ", err);
                reject(data);
            })
        })
    },
    
    /* 
     * get exits from an investor's portfolio
     * (entities they invested in in the past, that have been acquired)
     */
    getPortfolioExits: (investorId, entityType, limit, page = 1) => {

        const filters = prepareFilterParam([
            // get acquisition rounds
            {
                key: 'funding_stage_filter',
                value: [FUNDING_STAGES.acquisition],
                op: ALLOWED_QUERY_OPERATOR.equal
            },
            // exclude draft rounds
            {
                key: 'verification_status',
                value: [FUNDING_STATUS.draft],
                op: ALLOWED_QUERY_OPERATOR.notEq
            },
            {
                key: 'previous_investor_filter',
                value: [investorId],
                op: ALLOWED_QUERY_OPERATOR.equal,
            },
            {
                key: 'previous_investor_type_filter',
                value: [entityType],
                op: ALLOWED_QUERY_OPERATOR.equal,
            }
        ]);

        const fields = prepareSelectParam([
            'id', 'investedin_data', 'primary_industry_data', 'location_data', 'date_raised', 'time_to_exit', 'investment_vehicle_data', 'amount', 'investors_data', 'previous_investors_data'
        ]);

        const url = `${API_URL_NEW}/funding-breakdown?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=date_raised^:DESC`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        rounds: response.data.data,
                        totalRecords: response.data.navigation.total
                    });
                } else {
                    resolve({
                        rounds: [],
                        totalRecords: 0
                    });
                }
            }).catch((err) => reject(err));
        });

    }

}

export default exitApi;